import React from "react"
import Layout from "../../Components/pageComponents/contentContainer"
import { Container } from "reactstrap"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import style from "./styles.module.css"

export default function DisasterRecovery() {

  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "solutions/Physical Security/Physical Security.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pub: file(relativePath: {eq: "solutions/Physical Security/7008617_preview (1).png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  )
  // const description = "Disaster Recovery Plan protects Business IT Infrastructure and will prepare the enterprise against total havoc in the instances of both a minor and a major disaster. This may include mirroring, replication, and other Data Management to avoid total loss of important business data and information. Fastlink will equip enterprises to avoid off-setting the business operations at a time that any disaster may occur. ";

  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="Physical Security">
      <Container
        fluid
        // className="pt-5 pb-5 pl-5 pr-5"
        style={{
          background: `rgb(243, 243, 243)`,
          paddingTop: `2rem`,
          paddingBottom: `3rem`,

        }}
      >
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>Solutions we offer</span>
        </h1>
        {/* 
                <SolutionInfoSection title="Physical Security" description={description}
                    img={Image.pub.childImageSharp.fluid} /> */}
        <div className={style.audioVideoContainer}>

          <div
            style={{
              width: `450px`, marginLeft: `20px`,
              marginRight: `20px`,
              lineHeight: `30px`,
            }}>
            <h2 style={{ marginBottom: `15px` }}>Physical Security</h2>
            <ul>
              <li>Residential and Office Security</li>
              <li>Enterprise Security</li>
              <li>Healthcare Security</li>
              <li>School Security</li>
            </ul>
          </div>
          <div
            style={{
              width: `450px`, marginLeft: `20px`,
              marginRight: `20px`,
              lineHeight: `30px`,
            }}>
            <Img fluid={Image.pub.childImageSharp.fluid} />
          </div>

        </div>

      </Container>

    </Layout>

  )
}